import React, {Component} from 'react';
import Modal from "../../common/containers/Modal";
import AdvisoryLeadForm from "../../advisory/AdvisoryLeadForm";
import URLS from "../../urls";

class AdvisoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        window.showAdvisoryModal = () => {
            this.openModal();
        };
    }

    openModal() {
        this.setState(state => ({...state, showModal: true}));
    }

    closeModal() {
        this.setState(state => ({...state, showModal: false}));
    }

    render() {
        return (
            <Modal
                title="Assessoria acadêmica"
                show={this.state.showModal}
                onCancel={this.closeModal}
                width="large"
            >
                <AdvisoryLeadForm
                    asPanel={false}
                    successElement={
                        <h4>
                            Clique <a href={URLS.advisoryProducts} target="_blank">aqui</a> se quiser saber mais sobre nossa assessoria acadêmica.
                        </h4>
                    }
                />
            </Modal>
        );
    }
}

export default AdvisoryModal;