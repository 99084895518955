import React from 'react';
import Modal from '../../common/containers/Modal';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';
import './diff.css';

class TOSModificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      content: '',
    };
    this.onSaveClick = this.onSaveClick.bind(this);
    this.url = `${Config.apiHost}profiles/modified_tos/`;
  }

  componentDidMount() {
    this.fetchTOS();
  }

  fetchTOS() {
    Ajax.get(this.url).done((data) => {
      this.setState((state) => ({
        ...state,
        show: data.show,
        content: data.diff_content,
      }));
    });
  }

  onSaveClick() {
    Ajax.post(this.url).done(() =>
      this.setState((state) => ({
        ...state,
        show: false,
      }))
    );
  }

  render() {
    const { i18n } = this.props;
    return (
      <Modal
        title={i18n.t('Termos e Condições de Uso')}
        show={this.state.show}
        footer={
          <button className="btn btn-primary btn-lg" onClick={this.onSaveClick}>
            {i18n.t('Concordo')}
          </button>
        }
        width="large">
        <p className="text-muted">
          {i18n.t(
            'Algumas alterações foram feitas nos Termos e Condições de Uso do FastFormat, as quais estão destacadas no novo texto abaixo.'
          )}
        </p>
        <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
      </Modal>
    );
  }
}

export default withNamespaces()(TOSModificationModal);
