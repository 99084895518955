import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";
import Config from "../config";
import Ajax from "../common/ajax";
import Alert from "../common/components/Alert";

class EmailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: null,
            clicked: false,
        };
        this.fetchEmailAddress = this.fetchEmailAddress.bind(this);
        this.sendConfirmation = this.sendConfirmation.bind(this);
    }

    componentDidMount() {
        this.fetchEmailAddress();
    }

    fetchEmailAddress() {
        const url = `${Config.apiHost}email-confirmation/`;
        Ajax.get(url).done(emailAddresses => {
            this.setState(state => ({
                ...state,
                emailAddress: emailAddresses[0]
            }));
        });
    }

    sendConfirmation() {
        const url = `${Config.apiHost}email-confirmation/send_confirmation/`;
        Ajax.post(url);
        this.setState(state => ({...state, clicked: true}));
    }

    isConfirmationVisible() {
        if (this.state.emailAddress &&
            !this.state.emailAddress.verified &&
            this.state.emailAddress.seconds_since_email_sent) {
            const hours = this.state.emailAddress.seconds_since_email_sent/60;
            return hours >= 1;
        }
        return false;
    }

    render() {
        if (!this.isConfirmationVisible()) {
            return null;
        }

        const {i18n} = this.props;

        if (this.state.clicked) {
            return (
                <Alert type="success">
                    {i18n.t('Link enviado com sucesso para')}
                    {' '}
                    <b>{this.state.emailAddress.email}.</b>
                    {' '}
                    {i18n.t('Verifique seu e-mail e clique no link recebido.')}
                </Alert>
            );
        }

        return (
            <Alert type="warning">
                {i18n.t('Você ainda não confirmou seu endereço de e-mail.')}
                {' '}
                {i18n.t('Para reenviar o link de confirmação para seu e-mail')}
                {', '}
                <a role="button" onClick={this.sendConfirmation}>
                    {i18n.t('clique aqui')}.
                </a>
            </Alert>
        );
    }
}

export default withNamespaces()(EmailConfirmation);
