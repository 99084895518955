import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrivatePage from '../common/containers/PrivatePage';
import Content from '../common/containers/Content';
import ProfileModal from '../account/profile/ProfileModal';
import DocumentList from './DocumentList';
import ErrorBoundary from '../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import AdvisoryModal from '../editor/components/AdvisoryModal';
import TOSModificationModal from '../account/profile/TOSModificationModal';
import SignupDiscount from '../sales/SignupDiscount';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';

class DocumentsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, category, ...restProps } = this.props;
    window.document.title = 'FastFormat';

    return (
      <PrivatePage title={this.props.title}>
        <Content>
          <ErrorBoundary>
            <div className="hidden-lg hidden-xl">
              <Row>
                <Col md={10} mdOffset={1}>
                  <SignupDiscount />
                </Col>
              </Row>
            </div>
            <DocumentList category={category} {...restProps} />
            <AdvisoryModal />
          </ErrorBoundary>
        </Content>
        <ProfileModal />
        <TOSModificationModal />
      </PrivatePage>
    );
  }
}

DocumentsPage.propTypes = {
  title: PropTypes.string.isRequired,
  ...DocumentList.propTypes,
};

DocumentsPage.defaultProps = {
  ...DocumentList.defaultProps,
};

export default withNamespaces()(DocumentsPage);
