import React from 'react';
import { withNamespaces } from 'react-i18next';
import Col from '../common/containers/Col';
import Jumbotron from '../common/containers/Jumbotron';
import Row from '../common/containers/Row';

class DocumentListFirstStepVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeCols: 12,
      iframeAutoplay: 0,
    };

    this.monitorIframevideo = this.monitorIframevideo.bind(this);
    this.monitorTimeout = null;
    this.idIframeVideo = 'iframe-youtube-video';
  }

  componentDidMount() {
    // this.monitorIframevideo();
  }

  componentWillUnmount() {
    clearTimeout(this.monitorTimeout);
  }

  monitorIframevideo() {
    console.log('youtube iframe monitoring triggered');
    const activeEl = document.activeElement;
    if (activeEl.id === this.idIframeVideo) {
      this.setState((state) => ({ ...state, iframeCols: 12, iframeAutoplay: 1 }));
    } else {
      this.monitorTimeout = setTimeout(this.monitorIframevideo, 300);
    }
  }

  render() {
    const { i18n, hasDocuments, isPresentations } = this.props;

    if (hasDocuments) {
      return null;
    }

    const timeline = (
      <ul className="user-timeline user-timeline-compact">
        <li className="latest">
          <div className="user-timeline-title">
            <b>{i18n.t('Passo')} 1</b> -{' '}
            {isPresentations ? i18n.t('Clique em "Nova apresentação"') : i18n.t('Clique em "Novo documento"')}
          </div>
        </li>
        <li className="latest">
          <div className="user-timeline-title">
            <b>{i18n.t('Passo')} 2</b> - {i18n.t('Selecione um dos modelos de formatação disponíveis')}
          </div>
        </li>
        <li className="latest">
          <div className="user-timeline-title">
            <b>{i18n.t('Passo')} 3</b> - {i18n.t('Escreva seu texto ou importe de um arquivo MS Word')}
          </div>
        </li>
        <li className="latest">
          <div className="user-timeline-title">
            <b>{i18n.t('Passo')} 4</b> -{' '}
            {isPresentations ? i18n.t('Visualize e edite sua apresentação') : i18n.t('Visualize e edite seu documento')}
          </div>
        </li>
        <li className="latest">
          <div className="user-timeline-title">
            <b>{i18n.t('Passo')} 5</b> -{' '}
            {isPresentations
              ? i18n.t('Faça download da apresentação formatada')
              : i18n.t('Faça download do documento formatado')}
          </div>
        </li>
        <li>
          <div className="user-timeline-title text-muted" style={{ fontSize: '16px' }}>
            {i18n.t(
              'Caso precise de auxílio, no menu "Ajuda" você encontra uma lista de vídeos que ensinam todas as funcionalidades do FastFormat.',
            )}
          </div>
        </li>
      </ul>
    );

    return (
      <Jumbotron style={{ backgroundColor: 'white' }}>
        <h2>
          {isPresentations
            ? i18n.t('Veja como é fácil formatar sua apresentação')
            : i18n.t('Veja como é fácil formatar seu documento')}
        </h2>
        <hr />
        <br />
        <Row>
          {this.state.iframeCols < 12 && <Col md={6}>{timeline}</Col>}

          <Col md={this.state.iframeCols}>
            <iframe
              id={this.idIframeVideo}
              width="100%"
              style={{ aspectRatio: '16/9' }}
              src={`https://www.youtube.com/embed/PDQR0sD4VUw?modestbranding=1&rel=0&showinfo=0&autoplay=${this.state.iframeAutoplay}`}
              title={i18n.t('Primeiros passos no FastFormat')}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Col>
        </Row>

        {this.state.iframeCols === 12 && (
          <Row>
            <Col md={12}>
              <hr />
              {timeline}
            </Col>
          </Row>
        )}
      </Jumbotron>
    );
  }
}

export default withNamespaces()(DocumentListFirstStepVideo);
