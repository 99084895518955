import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../common/components/SearchInput';
import URLS from '../../urls';
import { Link } from 'react-router-dom';
import SortDropdown from '../../common/components/SortDropdown';
import { withNamespaces } from 'react-i18next';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      template_meta__name: '',
      last_modification_date: '',
    };

    this.handleSortClick = this.handleSortClick.bind(this);
  }

  getSortItems() {
    const { i18n } = this.props;

    return [
      {
        label: i18n.t('Título'),
        attribute: 'title',
      },
      {
        label: i18n.t('Modelo'),
        attribute: 'template_meta__name',
      },
      {
        label: i18n.t('Última modificação'),
        attribute: 'last_modification_date',
      },
    ];
  }

  handleSortClick(sortField, ordering) {
    this.props.handleSort(sortField, ordering);
  }

  render() {
    const { i18n, type } = this.props;
    const isPresentations = type === 'presentations';

    return (
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-md-7">
            <div className="btn-group">
              <Link
                to={isPresentations ? URLS.presentationTemplates : URLS.documentTemplates}
                className="btn btn-primary btn-lg pull-left"
                aria-expanded="false"
              >
                {type === 'presentations' ? i18n.t('Nova apresentação') : i18n.t('Novo documento')}
              </Link>
            </div>{' '}
            <div className="btn-group hidden-xs" role="group">
              <SortDropdown
                onSortClick={this.handleSortClick}
                onClear={() => this.handleSortClick('', null)}
                items={this.getSortItems()}
                buttonClass="btn btn-default btn-lg"
              />
            </div>
          </div>

          <div className="col-md-5 pull-right hidden-xs">
            <SearchInput handleSearch={this.props.handleSearch} focus={false} />
          </div>
        </div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['documents', 'presentations']),
};

Toolbar.defaultProps = {
  handleSearch: function (term) {
    console.log('Handle search called with param:', term);
  },
};

export default withNamespaces()(Toolbar);
