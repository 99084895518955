import React from 'react';
import Modal from '../../common/containers/Modal';
import ProfileForm from './ProfileForm';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';

class ProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      profile: {},
    };
    this.onModalClose = this.onModalClose.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.saveFunc = null;
  }

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile() {
    const url = `${Config.apiHost}profiles/current/`;
    const fields = ['fullname', 'broader_area', 'area'];
    Ajax.get(url, fields).done((profile) => {
      if (!profile.fullname || !profile.broader_area || !profile.area) {
        this.setState((state) => ({
          ...state,
          show: true,
          profile,
        }));
      }
    });
  }

  onModalClose() {
    this.setState((state) => ({
      ...state,
      show: false,
    }));
  }

  onSaveClick() {
    this.saveFunc && this.saveFunc();
  }

  renderFooter() {
    const { i18n } = this.props;
    return (
      <button className="btn btn-primary btn-lg" onClick={this.onSaveClick}>
        {i18n.t('Salvar')}
      </button>
    );
  }

  render() {
    const { i18n } = this.props;
    return (
      <Modal
        title={i18n.t('Personalize sua experiência')}
        show={this.state.show}
        headerFullColor="primary"
        // onCancel={this.onModalClose}
        footer={this.renderFooter()}
      >
        <ProfileForm
          fields={['fullname', 'broader_area', 'area']}
          saveFuncRef={(func) => (this.saveFunc = func)}
          onSuccess={this.onModalClose}
        />
      </Modal>
    );
  }
}

export default withNamespaces()(ProfileModal);
