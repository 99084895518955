import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Checkbox from './form/Checkbox';

class SortDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: (this.props.items && this.props.items.slice()) || [],
      checkboxItems:
        (this.props.checkboxItems && this.props.checkboxItems.slice()) || [],
    };
    this.handleSortClick = this.handleSortClick.bind(this);
    this.clear = this.clear.bind(this);
  }

  hasActive() {
    return (
      this.state.items.find((i) => !!i.active) ||
      this.state.checkboxItems.find((i) => !!i.checked)
    );
  }

  clear() {
    this.setState((state) => ({
      ...state,
      items: state.items.map((i) => ({ ...i, active: false })),
      checkboxItems: state.checkboxItems.map((i) => ({ ...i, checked: false })),
    }));
    this.props.onClear();
  }

  handleSortClick(item) {
    if (item.ordering === 'asc') {
      item.ordering = 'desc';
    } else {
      item.ordering = 'asc';
    }

    this.state.items.forEach((i) => {
      i.active = item.attribute === i.attribute;
    });

    this.setState((state) => ({ ...state }));

    this.props.onSortClick(item.attribute, item.ordering);
  }

  renderItems() {
    return this.state.items.map((item) => {
      const ordering = item.ordering ? item.ordering : 'asc';
      return (
        <li key={item.attribute} className={item.active ? 'active' : ''}>
          <a onClick={() => this.handleSortClick(item)} role="button">
            {item.label}
            &nbsp;
            <i className={`mdi mdi-sort-amount-${ordering} pull-right`} />
          </a>
        </li>
      );
    });
  }

  renderCheckboxItems() {
    if (!this.state.checkboxItems) {
      return null;
    }
    return this.state.checkboxItems.map((item) => {
      return (
        <li key={item.attribute}>
          <a>
            <Checkbox
              id={item.attribute}
              label={item.label}
              checked={!!item.checked}
              asFormGroup={false}
              onChange={() =>
                this.setState((state) => ({
                  ...state,
                  checkboxItems: state.checkboxItems.map((i) => {
                    const newItem = { ...i };
                    if (newItem.attribute === item.attribute) {
                      newItem.checked = !newItem.checked;
                      this.props.onCheckboxChange(newItem);
                    }
                    return newItem;
                  }),
                }))
              }
            />
          </a>
        </li>
      );
    });
  }

  render() {
    const { i18n } = this.props;

    return (
      <>
        <button
          type="button"
          className={`${this.props.buttonClass} dropdown-toggle ${
            this.props.disabled ? 'disabled' : ''
          } ${this.hasActive() ? 'active' : ''}`}
          data-toggle="dropdown">
          <span className="hidden-md hidden-sm">
            {this.props.label || i18n.t('Ordenar')}
          </span>{' '}
          <i className={this.props.iconClass} />
        </button>
        <ul className="dropdown-menu">
          {this.props.onClear && this.hasActive() && (
            <>
              <li>
                <a role="button" onClick={this.clear}>
                  <i className="icon mdi mdi-close-circle-o" />{' '}
                  {i18n.t('Limpar seleção')}
                </a>
              </li>
              <li className="divider" />
            </>
          )}
          {this.renderCheckboxItems()}
          {this.props.items && this.props.checkboxItems && (
            <li className="disabled">
              <a>{i18n.t('Ordenação')}</a>
            </li>
          )}
          {this.renderItems()}
        </ul>
      </>
    );
  }
}

SortDropdown.propTypes = {
  label: PropTypes.string,
  iconClass: PropTypes.string,
  buttonClass: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      attribute: PropTypes.string.isRequired,
    })
  ),
  checkboxItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      attribute: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
  /**
   * A function receiving the sorting attribute and the
   * ordering ('asc' or 'desc').
   */
  onSortClick: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func,
  onClear: PropTypes.func,
};

SortDropdown.defaultProps = {
  iconClass: 'mdi mdi-caret-down',
  buttonClass: 'btn btn-link',
};

export default withNamespaces()(SortDropdown);
