import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Jumbotron extends Component {
  render() {
    const { refFunc, ...rest } = this.props;
    return (
      <div className="jumbotron" ref={refFunc} {...rest}>
        {this.props.children}
      </div>
    );
  }
}

Jumbotron.propTypes = {
  size: PropTypes.oneOf(['']),
  refFunc: PropTypes.func,
};

export default Jumbotron;
